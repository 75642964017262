import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Card } from 'antd';
import Masonry from 'react-masonry-component';
import Lightbox from 'lightbox-react';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';

import Layout from '../components/layout';
import SEO from '../components/seo';

const prismicURL = 'https://blackway.prismic.io/api/v2';

const CustomGallery = ({ category }) => {
	const [ bikes, setBikes ] = useState([]);
	const [ tabPos, setTabPos ] = useState('top');

	const updateTabPos = () => {
		if (window)
			window.innerWidth < 768 ? setTabPos('top') : setTabPos('left');
	};

	const [ pictures, setPictures ] = useState([]);
	const [ fullscreenGallery, setFullscreenGallery ] = useState(false);
	const [ fullscreenIndex, setFullscreenIndex ] = useState(null);

	const toggleFullscreenGallery = (index = null) => {
		setFullscreenGallery(!fullscreenGallery);
		setFullscreenIndex(index);
	};

	const setLightboxPictures = (index, items = null) => {
		let pics = [];

		(items === null ? bikes : items)[index].images.forEach(image => {
			pics.push(image);
		});

		setPictures(pics);
	};

	useEffect(() => {
		const items = [];

		Prismic.getApi(prismicURL).then((api) => {
			return api.query(
				Prismic.Predicates.in('document.id', category.items)
			)
		})
			.then(response => {
				response.results.forEach(val => {
					let item = {
						title: val.data.title[0].text,
						description: PrismicDOM.RichText.asHtml(val.data.description),
						images: [],
						tmbs: []
					};

					val.data.images.forEach(img => {
						item.images.push(img.image.url);
						item.tmbs.push(img.image.tmb.url);
					});

					items.push(item);
				});

				setBikes(items);

				if (items.length > 0)
					setLightboxPictures(0, items);
			});

		updateTabPos();

		window.addEventListener('resize', updateTabPos, false);
		return () => window.removeEventListener('resize', updateTabPos, false);
	}, []);

	return (
		<>
			<Tabs defaultActiveKey="0" tabPosition={tabPos} onChange={setLightboxPictures}>
				{bikes.map((item, key) => (
					<Tabs.TabPane tab={item.title} key={key}>
						<h3 style={{  padding: '20px 30px', textAlign: 'left', fontSize: '1.6rem' }}>{item.title}</h3>
						<Row type="flex" gutter={16}>
							<Col xs={24} lg={12} style={{ textAlign: 'left' }}>
								<div
									style={{ padding: '0 30px' }}
									dangerouslySetInnerHTML={{ __html: item.description }}
								/>
							</Col>
							<Col xs={24} lg={12}>
								<Masonry>
									{item.tmbs.map((image, key) => (
										<Col key={key} xs={12} md={8} lg={6} xl={4}>
											<Card size="small" onClick={() => toggleFullscreenGallery(key)} hoverable>
												<img src={image} alt={item.name} style={{ maxWidth: '100%' }} />
											</Card>
										</Col>
									))}
								</Masonry>
							</Col>
						</Row>
					</Tabs.TabPane>
				))}
			</Tabs>

			{
				fullscreenGallery && (
					<Lightbox
						mainSrc={pictures[fullscreenIndex]}
						nextSrc={pictures[(fullscreenIndex + 1) % pictures.length]}
						prevSrc={pictures[(fullscreenIndex + pictures.length - 1) % pictures.length]}
						onCloseRequest={toggleFullscreenGallery}
						onMovePrevRequest={() => setFullscreenIndex((fullscreenIndex + pictures.length - 1) % pictures.length)}
						onMoveNextRequest={() => setFullscreenIndex((fullscreenIndex + 1) % pictures.length)}
					/>
				)
			}
		</>
	);
};

const CustomBikes = () => {
	const [ categories, setCategories ] = useState([]);

	useEffect(() => {
		Prismic.getApi(prismicURL).then((api) => {
			return api.query(
				Prismic.Predicates.at('document.type', 'categories_realisations')
			)
		})
			.then(response => {
				const cats = [];

				response.results[0].data.body.forEach((val, index) => {
					let cat = {
						id: index,
						name: val.primary.name[0].text,
						items: []
					};

					val.items.forEach(item => {
						if (item.realisations.id)
							cat.items.push(item.realisations.id);
					});

					cats.push(cat);
				});

				setCategories(cats);
			}, (err) => {
				console.log('error', err);
			});
	}, []);

	return (
		<Layout>
			<SEO
				title="Nos réalisations"
				description="Retrouvez toutes les réalisations de préparations et de modifications des motos de nos clients."
				lang="fr"
				url="/customs"
			/>

			<div className="box-container first">
				<div className="content-block">
					<div className="content-block-bg" />
					<h2>NOS RÉALISATIONS</h2>

					{
						categories.length > 0 && (
							<Tabs defaultActiveKey="0">
								{categories.map((item, key) => (
									<Tabs.TabPane tab={item.name} key={key}>
										<CustomGallery category={item} />
									</Tabs.TabPane>
								))}
							</Tabs>
						)
					}
				</div>
			</div>
		</Layout>
	);
};

export default CustomBikes;
